window.globalf = {
    'toggleMenu': function () {
        jQuery('.js_primaryNav').slideToggle();
        return false;
    },

    'toggleClass': function($source) {
        var target = $source.attr('data-target');
        var targetClass= $source.attr('data-targetclass');

        if(target && targetClass) {
            jQuery(target).toggleClass(targetClass);
        }
    },

    'styleNinjaForms': function() {
        // Loop until ninja forms exist

        if(jQuery('.nf-field-container').length > 0) {
            jQuery('.js_formSmallCheckboxThis').closest('nf-field').addClass('small-checkbox-wrapper');
            jQuery('.js_formSmallInputThis').closest('nf-field').addClass('small-input-wrapper');
        } else {
            setTimeout(window.globalf.styleNinjaForms, 400);
        }
    },

    'handleMobileMenu': function() {
        jQuery('.js_primaryNav .nav .menu-item-has-children > a').on('click', function() {
            var result = true;
            var tapVar = 'data-hastap';

            if(jQuery(window).width() < 1200) {
                var $this = jQuery(this);

                if ($this.attr(tapVar) != 1) {
                    jQuery('.js_primaryNav .nav .menu-item-has-children > a').removeAttr(tapVar);
                    $this.attr(tapVar, 1);
                    result = false;
                }
            }

            return result;
        });
    }
};



/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                window.globalf.handleMobileMenu();
                $('.js_primaryNavToggle').on('click', window.globalf.toggleMenu);
                $('.js_toggleClass').on('click', function() {
                    window.globalf.toggleClass($(this));
                });

                $('.top-menu-search-classer').on('click', function() {
                    $(this).find('.search-field').focus();
                });

                // Customize menu element to handle toggling
                $('.js_menuElement .menu-item-has-children').on('click', function() {
                    $(this).toggleClass('open');

                    return false;
                });

                if(jQuery('.ninja-forms-noscript-message').length > 0) {
                    window.globalf.styleNinjaForms();
                }

                $('body').on('change', '.js_referrerAllowOne input[type="checkbox"]', function() {
                    var that = this;
                    // Unselect others
                    $('.js_referrerAllowOne input[type="checkbox"]').each(function(k,v) {
                        if(v !== that) {
                            $(v).removeAttr('checked');
                        }
                    });
                });

                // Form listener
                jQuery( document ).on( 'nfFormReady', function() {
                      nfRadio.channel('forms').on('submit:response', function(form) {
                        window.dataLayer = window.dataLayer||[];
                        dataLayer.push({
                          event: 'ninjaFormSubmission',
                          eventData: form.data,
                          formID: form.data.form_id,
                          formTitle: form.data.settings.title
                      })
                    })
                  } );
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.